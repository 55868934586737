<!--
 * @Author: hhy
 * @Description: 财务管理--客户账号--客户账号
-->
<template>
  <scroll-layout>
    <template #header>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        title="客户列表"
        operateFixedType="right"
        :data="tableData"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <ykc-button
            v-for="(button, index) in pageButtons.filter(item => item.enabled())"
            :key="button.id || index"
            v-bind="button"
            @click="handlePageButtonClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          direct="flex-end"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageInfo.pageSize"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <!-------------------------------------------------
    description:钱包充扣
    --------------------------------------------------->
    <wallet-recharge-deduction
      :show1="show1"
      :row="currentRowWRD"
      @wallet-close="onWalletClose"
      @refresh-list="onRefreshList" />
    <!-------------------------------------------------
    description:分配扣回
    --------------------------------------------------->
    <distribution-rebate
      :show2="show2"
      :row="currentRowDR"
      @refresh-list="onRefreshList"
      @distribution-close="onDistributionClose" />
  </scroll-layout>
</template>

<script>
  import { customerAccount } from '@/service/apis';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { localGetItem, offlineExport, code } from '@/utils';

  import DistributionRebate from '../components/DistributionRebate.vue';
  import WalletRechargeDeduction from '../components/WalletRechargeDeduction.vue';

  export default {
    name: 'CustomerAccountsList',
    components: {
      DistributionRebate,
      WalletRechargeDeduction,
    },
    data() {
      return {
        show1: false,
        show2: false,
        currentRowWRD: {}, // 当前选择的行数据 钱包充扣
        currentRowDR: {}, // 当前选择的行数据 分配扣回
        operatorProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        treeData: [
          {
            id: 1,
            name: '工作台',
            parentId: 0,
            sort: 0,
            subNodes: [],
          },
          {
            id: 2,
            name: '财务管理',
            parentId: 0,
            sort: 0,
            subNodes: [
              {
                id: 3,
                name: '账户管理',
                parentId: 2,
                sort: 0,
                subNodes: [
                  {
                    id: 4,
                    name: '公司充电账户',
                    parentId: 3,
                    sort: 0,
                    subNodes: [],
                  },
                ],
              },
              {
                id: 9,
                name: '发票管理',
                parentId: 2,
                sort: 0,
                subNodes: [
                  {
                    id: 10,
                    name: '发票列表',
                    parentId: 9,
                    sort: 0,
                    subNodes: [],
                  },
                ],
              },
            ],
          },
        ],
        ruleForm: {
          name: '',
          tips: '',
          date: '',
          orgName: '',
          orgName1: '',
          img: '',
          file: '',
          orgType: '',
          adjustType: '1',
          refund: 0,
          label: [],
          dropdown: '',
          textarea: '',
        },
        rules: {
          name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
          tips: [{ required: true, message: '请输入温馨提示', trigger: 'blur' }],
          date: [{ required: true, message: '请选择日期', trigger: 'change' }],
          orgName: [{ required: true, message: '请输入机构名称', trigger: 'blur' }],
          orgName1: [{ required: true, message: '请输入机构名称1', trigger: 'blur' }],
          img: [{ required: true, message: '请上传图片', trigger: 'change' }],
          file: [{ required: true, message: '请上传文件', trigger: 'change' }],
          orgType: [{ required: true, message: '请选择机构类型', trigger: 'change' }],
          dropdown: [{ required: true, message: '请选择', trigger: 'change' }],
          label: [{ type: 'array', required: true, message: '请选择标签', trigger: 'change' }],
        },
        id: '',
        dictionary: {},
        polymerizationInfo: {},
        pageButtons: [
          {
            enabled: () => code('finance:account:cus:export'),
            id: '2',
            text: '导出',
            type: 'plain',
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableColumns: [
          { label: '客户ID', prop: 'orgId', minWidth: '100px' },
          { label: '客户名称', prop: 'orgName', minWidth: '100px' },
          { label: '归属主客户', prop: 'mainOrgName', minWidth: '100px' },
          { label: '客户类型', prop: 'orgTypeStr', minWidth: '100px' },
          { label: '钱包余额', prop: 'orgBalance', minWidth: '100px' },
        ],
        tableOperateButtons: [
          {
            enabled: () => code('finance:account:cus:wallet'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={['子客户'].includes(row.orgTypeStr)}
                onClick={() => {
                  this.currentRowWRD = { ...row };
                  this.show1 = true;
                }}>
                钱包充扣
              </ykc-button>
            ),
          },
          // {
          //   id: '1',
          //   text: '钱包充扣',
          //   // eslint-disable-next-line no-unused-vars
          //   handleClick: (btn, { row }) => {
          //     // this.$router.push({
          //     //   path: '/financeManage/agentCommissionApplication/edit',
          //     //   query: { id: row.id },
          //     // });
          //     this.show1 = true;
          //   },
          // },
          {
            enabled: () => code('finance:account:cus:settingded'),
            id: '2',
            text: '分配扣回',
            handleClick: (btn, { row }) => {
              console.log(btn, row);
              // this.$router.push({
              //   path: '/financialManagement/customerAccount/customDriverAccountsDetail',
              //   query: { id: row.id },
              // });
              this.currentRowDR = { ...row };
              this.show2 = true;
            },
          },
          {
            enabled: () => code('finance:account:cus:detail'),
            id: '3',
            text: '详情',
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/financialManagement/customerAccount/customerAccountsDetail',
                query: { id: row.orgId },
              });
            },
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          orgName: '',
          mainOrgName: '',
          orgType: '',
          orgId: '',
        },
        showDrawer: false,
        isEdit: false,
      };
    },
    created() {
      const dictionary = localGetItem('dictionary');
      if (dictionary?.customer_type) {
        this.dictionary = dictionary.customer_type.map(item => ({
          id: item.code,
          name: item.name,
        }));
      }
      this.searchTableList();
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'orgName',
            label: '客户名称',
            placeholder: '请输入客户名称',
          },
          {
            comName: 'YkcInput',
            key: 'mainOrgName',
            label: '主客户',
            placeholder: '请输入主客户',
          },
          {
            comName: 'YkcDropdown',
            key: 'orgType',
            data: this.dictionary,
            label: '客户类型',
            placeholder: '请选择客户类型',
          },
          {
            comName: 'YkcInput',
            key: 'orgId',
            label: '客户ID',
            placeholder: '请输入客户ID',
          },
        ];
      },
    },
    methods: {
      // 钱包充扣 关闭 事件
      onWalletClose(e, val) {
        console.log('onWalletClose:', e, val);
        this.show1 = false;
      },
      onRefreshList() {
        this.searchTableList();
      },
      // 分配回扣 关闭 事件
      onDistributionClose(e, val) {
        console.log('e:', e, val);
        this.show2 = false;
      },
      changeStateSearch() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        const customerAccountsReqParams = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        customerAccount.list(customerAccountsReqParams).then(response => {
          this.tableData = response?.records || [];
          this.pageInfo.total = response?.total || 0;
        });
      },
      /** 导出文件 */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'finance_export',
            jsonNode: {
              downloadKey: 'customerList',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        // if (form.createTime.length > 0) {
        //   [this.searchParams.createBeginTime, this.searchParams.createEndTime] = form.createTime;
        // }
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
    },
  };
</script>
<style lang="scss" scoped>
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
